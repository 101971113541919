// Here you can add other styles

.error {
  display: block;
  width  : 100%;
  color  : red;
  padding: 5px;
}




.table-responsive {
  height       : calc(100vh - 270px);
  margin-bottom: 20px;

  th {
    border: 0px !important;
  }

  thead {
    position       : sticky;
    top            : 0px;
    background     : white;
    padding-top    : -5px;
    border-collapse: separate !important;
  }

  tbody {}

  th:after,
  th:before {
    content : '';
    position: absolute;
    left    : 0;
    width   : 100%;
  }

  th:before {
    top             : 0;
    border-top      : 1px solid;
    border-top-color: #d8dbe0;
  }

  th:after {
    bottom             : 0;
    border-bottom      : 2px solid;
    border-bottom-color: #d8dbe0;
  }

}


/* width */
.table-responsive::-webkit-scrollbar {
  width: 10px;
}

/* Track */
.table-responsive::-webkit-scrollbar-track {
  background   : #f1f1f1;
  border-radius: 10px;
}


/* Handle */
.table-responsive::-webkit-scrollbar-thumb {
  background   : lightgray;
  border-radius: 10px;
}

.table-responsive:hover::-webkit-scrollbar-thumb {
  background: lightgray;
}

/* Handle on hover */
.table-responsive::-webkit-scrollbar-thumb:hover {
  background: #888;
}

.about-me {
  max-width: 170px !important;
}

.data-tabel-header {
  display        : flex;
  width          : 100%;
  justify-content: space-between;
  flex-wrap      : wrap;
  align-items    : baseline;

  .pagination {
    margin: 3px;
  }
}


.table-loader {
  position : absolute;
  top      : 50%;
  left     : 50%;
  transform: translateX(-50%) translateY(-50%);
  z-index  : 100;

  background: #f5f5f561;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;   
}
.search-box::placeholder {
  font-size: 14px;
}

.text-capitalize{
  text-transform: capitalize;
}
